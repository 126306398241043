import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import logo from "../images/logo.svg";
import { footerElement } from "../styles/footer.module.scss";

const Footer = () => {
  return (
    <footer className={footerElement + " pt-3 pb-3"}>
      <Container>
        <Row>
          <Col>
            <p className="text-center mb-3">
              <img
                className="img-fluid w-50"
                src={logo}
                alt="Beauty Foundation"
              />
            </p>
            <p className="text-center mb-1">
              © 2022 Wegener-Hänger-Abasco Foundation for Beauty Treatments
            </p>
            <p className="text-center">
              Headquarters: F7M5+W7 Bora-Bora, French Polynesia
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
