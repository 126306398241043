import * as React from "react";
import { Container } from "react-bootstrap";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import icon from "../images/icon.png";

const Layout = ({ children, noHeader, noFooter }) => {
  let header = <Header />;
  if (noHeader) {
    header = null;
  }
  let footer = <Footer />;
  if (noFooter) {
    footer = null;
  }

  return (
    <main className="flex-shrink-0">
      <Helmet>
        <title>Beauty Foundation</title>
        <keywords>
          free plastic surgery, free treatment, breast reduction, breast lift,
          penis enlargement.
        </keywords>
        <description>
          This is the website of the Wegener-Hänger-Abasco Foundation for Beauty
          Treatments. We provide free plastic surgeries and other treatments for
          specific cases.
        </description>
        <link rel="icon" href={icon} />
      </Helmet>
      {header}
      <Container className="content">{children}</Container>
      {footer}
    </main>
  );
};

export default Layout;
